//styling for .hoverable dark sidebars
@include media-breakpoint-up($sidebar-desktop-min-width) {

    //for dark colored sidebars, submenus should inherit sidebar color
    @if $hoverable == true {
        .sidebar-color.hoverable.collapsed {
            .submenu .submenu,
            .submenu-inner,
            .submenu .nav-item
            //.submenu .nav-link,
            //.submenu .nav-text
            {
                background-color: inherit !important;
            }
            .nav > .nav-item > .nav-link > .nav-text {
                color: rgba(255, 255, 255, 0.93) !important;
            }

            .nav > .nav-item > .submenu {
                top: calc(100% - 2px);// move it a little higher to look better when fading in/out
            }

        
            .sub-arrow {
                &::before {
                    border-right-color: rgba(255, 255, 255, 0.33) !important;
                }
                &::after {
                    border-right-color: inherit !important;
                }
            }
        
            .sidebar-section-item .fadeable {
                border: 1px solid;
                box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
            }

            .submenu,
            .nav > .nav-item > .nav-link > .nav-text,
            .sidebar-section-item .fadeable {
                border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);       
            }

            .nav > .nav-item > .submenu {
                border-top-color: rgba(255, 255, 255, 0.2);
            }

            // hide the submenu bullets?
            .submenu .nav-text::before {
                display: none;
            }
        }// .sidebar-color.hoverable
    }// @if hoverable

    
    // when sidebar is gradient and a sidebar-section has .sidebar-gradientX class as well for collapsed mode styling
    // make that section transarent when not collapsed
    .sidebar[class*="sidebar-gradient"]:not(.collapsed) .sidebar-color {
        background-color: transparent;
        background-image: none;
    }


    @if $sidebar-hover == true {
        .sidebar-color.sidebar-hover:not(.collapsed) {
            .submenu .submenu,
            .submenu-inner,
            .submenu .nav-item {
                background-color: inherit !important;
            }

            .sub-arrow {
                &::before {
                    border-right-color: rgba(255, 255, 255, 0.33) !important;
                }
                &::after {
                    border-right-color: inherit !important;
                }
            }

            .submenu {
                border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
            }

            // hide the submenu bullets?
            .submenu .nav-text::before {
                display: none;
            }
        }
    }

    @if $horizontal == true {
        .sidebar-color.sidebar-h {
            .nav > .nav-item > .submenu ,
            .sidebar-section-item .fadeable {
                border: 1px solid;
                border-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) ;
                box-shadow: none !important;
            }
            
            .nav > .nav-item > .sub-arrow ,
            .sidebar-section-item .sub-arrow {
                &::before {
                    border-right-color: transparent !important;
                    border-bottom-color: inherit !important;
                }
                &::after {
                    border-right-color: transparent !important;
                    border-bottom-color: rgba(255, 255, 255, 0.33) !important;
                }
            }
        }
    }
}//media